/* eslint-disable */
import { useEffect, useState } from 'react';
import Chart from './Chart';
import { Fragment } from 'react';
import { Options } from 'highcharts';
import * as React from 'react';
// import { media } from '../styles/variables';

interface Props {
  indices: string;
  period: string;
  config: Options;
  maxDefault?: number;
  children: React.ReactNode;
}

const ChartContainer: React.FC<Props> = ({
  indices,
  period,
  config,
  maxDefault = null,
  children,
}): JSX.Element => {
  const [max, setMax] = useState<any>();
  const id = `${indices}-${period}`;

  useEffect((): void => {
    setMax(maxDefault);
  }, [maxDefault]);

  return (
    <Fragment>
      <h5 style={header}>{children}</h5>
      <div className="col-7 chart-container" style={container}>
        <Chart idStr={id} config={config} max={max} />
      </div>
      <div className="chart-operator">
        <div>{indicesName(indices)}の最大値</div>
        <select
          onChange={(e): void => {
            setMax(maxValue(e));
          }}
          className="form-control chart-select-max"
        >
          {selectOptions(indices, max)}
        </select>
      </div>
    </Fragment>
  );
};

function indicesName(indices: string): string {
  if (indices == 'fcf-ratio') {
    return 'FCF倍率';
  } else {
    return indices.toUpperCase();
  }
}

function selectOptions(indices: string, max?: any): JSX.Element[] {
  let values;

  if (indices == 'per') {
    values = [undefined, 10, 15, 20, 30, 50, 100];
  } else if (indices == 'pbr') {
    values = [undefined, 1, 2, 3, 5, 10, 20];
  } else {
    values = [undefined, 10, 20, 50, 100, 200, 500];
  }
  return values.map(
    (v, i): JSX.Element => (
      <option value={v} key={i} selected={v === max}>
        {v}
      </option>
    ),
  );
}

function maxValue(e: React.ChangeEvent<HTMLSelectElement>): number | null {
  const select = e.currentTarget;
  const val = select.options[select.selectedIndex].value; // IE 11
  return val ? parseInt(val) : null;
}

const header = {
  marginTop: '20px',
};

const container: any = {
  boxSizing: 'border-box',
  height: '400px',
  marginBottom: '20px',
  padding: '20px 10px 15px 10px',
  border: '1px solid #ddd',
  background: '#fff',
  boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1)',
};

export default ChartContainer;
/* eslint-enable */
